const Ga = {
    login: {
        title: "Logáil isteach",
        username: "Cuir isteach an t-ainm úsáideora",
        password: "Cuir isteach an focal faire",
        forgetPaw: "Ná déan dearmad ar an bhfocal faire",
        registerNow: "Gan cuntas? Cláraigh láithreach",
        doLogin: "Logáil isteach",

    },
    profile: {
        portrait: "Grianghraf",
        video: "Video",
        jianjie: "Próifíl phearsanta",
        liji: "Ceapachán láithreach",
        chongdan: "Raon muirir",
        fd: "Bean",
        yuyue: "Ceapachán",
        archives: "Comhad deirfiúr",
        age: "330.06",
        height: "Airde",
        weight: "Meáchan",
        cup: "Corn",
        city: "Cathracha",
        area: "Limistéar seirbhíse",
        item: "Míreanna seirbhíse"
    },
    register:{
        title: "Clárú",
        username: "Cuir isteach an t-ainm úsáideora (6-12 aibítir nó uimhir Béarla)",
        password: "Cuir isteach an focal faire logála isteach (6-12 aibítir nó uimhir Béarla)",
        code: "Cuir isteach an cód cuireadh",
        agreement: "Tá a fhios agam agus aontaíonn mé le conarthaí éagsúla an \"Comhaontú Oscailte Cuntas\"",
        chooseAgreement: "Seiceáil an comhaontú oscailte cuntas thíos!",
        doRegister: "Clárú",

    },
    footer:{
        home : "Leathanach baile",
        game: "Ceapachán",
        video: "Video",
        mine: "Mé",

    },
    game:{
        title: "Halla áirithinte",
        all: "Gach ceann",

    },
    choose:{
        title: "Roghnaigh Banphrionsa",
        city: "Cathair Daquan",
        rule: "Is é an t-ardán an líonra is mó fíor + gnó a bhaineann le gnó + paisean na cathrach, chun príobháideacht phearsanta gach úsáideora a áirithiú, ní féidir le custaiméirí ach dul i dteagmháil leis an bhfáiltiú nó leis an ardán chun an t-ainm fíor-ainm a mholadh.",
        price: "Próiseas praghais",
        resource: "Cad iad na hacmhainní?",
        character: "Glan dearg, samhlacha, freastalaithe eitilte, samhlacha tairisceana, mic léinn an choláiste, ach ní féidir leat smaoineamh, níl aon leibhéal ann",
        scope: "Raon seirbhíse?",
        location: "Tá airtléire saor in aisce sa chathair chéanna, áit ar bith ar fud na tíre, tá cathracha sa chéad agus sa dara háit sa tír, agus is féidir le cathracha tríú sraith ceapacháin a dhéanamh trí theagmháil leis an bhfáiltiú.",

    },
    home:{
        recommend: "Tasc molta",
        more: "Féach níos mó",
        popularity: "Rangú coitianta",
        hot: "Molta coitianta",

    },
    lottery:{
        jieguo: "Vóta",
        jiesu: "Deireadh",
        record: "Taifead tasc",
        rule: "Rialacha súgartha",
        prompt: "Leideanna súgartha",
        gameplay: "Roghnaigh uimhir ó fhoirm roghnach agus luach chun geall a dhéanamh",
        explain: "Treoracha a bhuaigh",
        wprole: "Tá suim na dtrí uimhir crannchuir 11 go 18 mór; Tá suim 3 go 10 beag;",
        example: "Sampla gealltóireachta",
        option: "Clár gealltóireachta: uimhir chrannchuir bheaga: 123, is é sin, beag agus meánmhéide",
        task: "Liosta tasc",
        money: "Iarmhéid atá ar fáil",
        submit: "Cuir isteach",
        selection: "An uimhir toghcháin reatha",
        perNote: "Méid gach nóta",
        inputMoney: "Cuir isteach an méid le do thoil",
        total: "Iomlán",
        note: "Iomlán",
        statistics: "Iomlán",
        empty: "Orduithe folamh",
        verify: "Dearbhú a chur isteach",
        issue: "Uimhir eisiúna",
        num: "Uimhir an chrannchuir",
        big: "Big",
        small: "Beaga",
        single: "Aonair",
        double: "Dúbailte",
        unselected: "Gan rogha",
        drawLottery: "Rath an chrannchuir, uimhir eisiúna:",
        kefu: "Déan teagmháil leis an riarthóir an tasc seo!",

    },
    mine:{
        recharge: "Recharge",
        withdraw: "Airgead tirim",
        myMoney: "Mo sparán",
        detail: "Sonraí",
        balance: "Iarmhéid",
        finance: "Tuarascáil phearsanta",
        order: "Sonraí an chuntas",
        record: "Taifead tasc",
        my: "Ionad pearsanta",
        notice: "Fógra faisnéise",
        service: "Seirbhís do chustaiméirí ar líne",
        score: "Pointí",
    },
    setting:{
        title: "Socraigh",
        basic: "Socruithe faisnéise bunúsacha",
        password: "Pasfhocal logála isteach",
        capital: "Pasfhocal an Chiste",
        lang: "5.36",
        quit: "Logáil isteach scoir",

    },
    setPayPassword: {
        title: "Socraigh focal faire ciste",
        opw: "Cuir isteach an focal faire ciste",
        tpw: "Cuir isteach an focal faire ciste arís",

    },
    gameRecord:{
        title: "Taifead tasc",
        amountBet: "Méid gealltóireachta",
        create: "Am ordú",
        update: "Am socraíochta",

    },
    video:{
        title: "Amharclann físe",
        play: "Seinn",

    },
    playVideo:{
        play: "Athsheinm",

    },
    setname:{
        title: "Athraigh an t-ainm fíor",
        name: "Ainm",
        truename: "Cuir isteach an t-ainm fíor",
        hint: "Chun do chuntas a bheith sábháilte, ní mór don ainm fíor a bheith comhsheasmhach leis an ainm cárta bainc ceangailteach",

    },
    setsex:{
        title: "Athruithe inscne",

    },
    bindCard:{
        title: "Líon isteach an cárta bailithe",
        msg: "Cuir isteach do chárta bailithe le do thoil",
        hint: "Úsáideoirí a chara, chun do shlándáil airgeadais a chosaint, ceangail d'ainm fíor agus do phasfhocal tarraingt siar a shocrú. Má tá an t-ainm neamhréireach le hainm an chuntas, ní bheidh sé in ann airgead a tharraingt siar.",
        card: "Cuntas (gnáth)",
        truecard: "Cuir isteach an cuntas (gnáth)",
        name: "Ainm an Chuntais (ainm fíor)",
        truename: "Cuir isteach ainm an chuntas (ainm fíor)",
        financial_institution: "Ainm na hinstitiúide airgeadais",
        truefinancial_institution: "Cuir isteach ainm na hinstitiúide airgeadais",
        branch: "Ainm brainse",
        truebranch: "Cuir isteach ainm an bhrainse",
        branch_number: "Uimhir brainse",
        truebranch_number: "Cuir isteach uimhir brainse"
    },
    setbank:{
        title: "Faisnéis cárta bailithe",
        bank: "Cuir cárta bainc leis",
        hint: "Leid: Ceangail bainc thráchtála mór, más gá duit a mhodhnú, téigh i dteagmháil le seirbhís do chustaiméirí ar líne",

    },
    setLoginPassword:{
        title: "Athraigh an focal faire logála isteach",
        old_password: "73821141",
        old_password_p: "Cuir isteach an seanfhocal faire",
        o_new_password: "73821141",
        o_new_password_p: "Cuir isteach an focal faire nua",
        t_new_password_p: "Cuir isteach an focal faire nua arís",

    },
    infomation:{
        title: "Eolas bunúsach",
        head: "Avatar",
        select: "Roghnaigh avatar",
        name: "Ainm fíor",
        sex: "Inscne",
        man: "Fireann",
        girl: "Mná",
        unknown: "Anaithnid",
        binding: "Faisnéis ceangailteach",

    },
    withdraw: {
        title: "Ionad tarraingt siar airgid",
        record: "Taifead tarraingt siar airgid",
        money: "Méid tarraingt siar airgid",
        all: "Gach ceann",
        min: "Teorainn amháin: an ceann is ísle",
        max: "",
        num: "Airgead Tirim: tarraingt siar airgid lae",
        hint: "Am teacht: Is é an t-am teacht ginearálta thart ar 5 nóiméad, agus is é an 2 nóiméad is tapúla a thagann.",
        quota: "Cur síos ar an teorainn",
        balance: "Iarmhéid",
        withdraw: "Airgead tirim láithreach",

    },
    withdrawRecord:{
        desc: "Míniú",
        create: "Cuir isteach am",
        update: "Am iniúchta",

    },
    personalreport:{
        title: "Tuarascáil phearsanta",
        profit: "Méid brabúis",
        formula: "Foirmle ríomh brabúis: Méid buaite-méid an tasc",
        task: "Méid an tasc",
        recharge: "Méid athluchtaithe",
        withdrawal: "Méid tarraingt siar airgid",
        win_money: "Méid buaite",

    },
    service: {
        title: "Seirbhís do chustaiméirí ar líne",
        relation: "Déan teagmháil le",
        hint: "7 * 24 uair sa lá tiomanta duit freastal ort",

    },
    msg:{
        "次": "Amanna",
        "用户不存在！": "Níl an t-úsáideoir ann!",
        "密码错误！": "Earráid phasfhocal!",
        "登录成功！": "Logáil isteach go rathúil!",
        "邀请码不存在！": "Níl an cód cuireadh ann!",
        "用户名位数错误！": "Ainm úsáideora uimhir mícheart!",
        "密码位数错误！": "Earráid phasfhocal!",
        "用户名不能存在中文！": "Ní féidir ainm úsáideora a bheith ann i Sínis!",
        "邀请码不能为空！": "Ní féidir an cód cuireadh a bheith folamh!",
        "注册成功！": "Clárú rathúil!",
        "注册失败！": "Theip ar chlárú!",
        "用户已存在": "Tá úsáideoirí ann cheana féin",
        "刷新成功！": "Athnuachan rathúil!",
        "请联系顾问或接待员": "Déan teagmháil le comhairleoir nó le fáilteoir",
        "金额错误！": "Méid mícheart!",
        "请选号！": "Roghnaigh uimhir le do thoil!",
        "请填写金额!": "Líon isteach an méid!",
        "余额不足，请联系客服充值！": "Easpa cothromaíochta, téigh i dteagmháil le seirbhís do chustaiméirí a athchlárú!",
        "没有更多了": "Níl níos mó",
        "没有数据": "Gan sonraí",
        "余额不足！": "Easpa iarmhéid!",
        "扣款失败！": "Theip ar asbhaint!",
        "下注异常！": "Geallta neamhghnácha!",
        "投注成功！": "Rath gealltóireachta!",
        "参数异常！": "Paraiméadair neamhghnácha!",
        "获取中": "Faigh...",
        "请完成任务单后进入": "Cuir an liosta tasc i gcrích",
        "请联系客服充值": "Déan teagmháil le seirbhís do chustaiméirí le do thoil",
        "请设置收款卡!": "Socraigh an cárta bailithe!",
        "功能已禁用!": "Tá feidhm faoi mhíchumas!",
        "账号下线": "Cuntas as líne an tionóil",
        "登录/注册": "Logáil isteach/clárú",
        "登录可享受更多服务！": "Is féidir le logáil isteach níos mó seirbhísí a bhaint amach!",
        "未设置": "Gan socraithe",
        "已设置": "Tá sé leagtha síos",
        "提交": "Cuir isteach",
        "确定": "OK",
        "请填写完整": "Líon isteach an t-iomlán",
        "两次密码不一致！": "Dhá focal faire neamhréireach!",
        "设置提现密码成功！": "Socraigh an focal faire tarraingt siar go rathúil!",
        "提现密码已设置，需要修改请联系客服": "Tá an focal faire tarraingt siar leagtha síos, ní mór duit a mhodhnú, téigh i dteagmháil le seirbhís do chustaiméirí",
        "已绑定": "Tá sé faoi cheangal",
        "无": "Uimh",
        "更换头像成功！": "D'éirigh le ceann athsholáthair T!",
        "更新头像失败！": "Theip ar an avatar a thabhairt cothrom le dáta!",
        "请勿重复设置！": "Ná déan na socruithe arís!",
        "设置姓名成功！": "Socraigh ainm rathúil!",
        "设置姓名失败！": "Teip ainm a leagan síos!",
        "设置性别成功！": "Socraigh rath inscne!",
        "设置性别失败！": "Socraigh teip inscne!",
        "请设置姓名后再绑定银行卡！": "Socraigh ainm agus ansin cuir an cárta bainc ar bun!",
        "请设置提现密码后再绑定银行卡！": "Socraigh an focal faire tarraingt siar agus ansin an cárta bainc a cheangal!",
        "确认绑卡": "Cárta ceangailteach deimhnithe",
        "请输入银行卡号！": "Cuir isteach an banc!",
        "请输入银行名称！": "Cuir isteach ainm an bhainc!",
        "设置银行卡成功！": "Socraigh rath cárta bainc!",
        "设置银行卡失败！": "Theip ar chárta bainc a bhunú!",
        "旧密码错误": "Earráid phasfhocal d'aois",
        "修改密码成功！": "Athraigh an focal faire go rathúil!",
        "修改密码失败！": "Theip ar athrú focal faire!",
        "请填写正确的金额": "Líon isteach an méid ceart",
        "提现金额不能少于：": "Ní féidir an méid aistarraingthe airgid a bheith níos lú ná:",
        "提现金额不能大于：": "Ní féidir an méid aistarraingthe airgid a bheith níos mó ná:",
        "当日提现次数已用完": "Tá an tarraingt siar airgid ar an lá críochnaithe",
        "提现成功！": "Rath tarraingt siar airgid!",
        "提现失败！": "Theip ar tharraingt siar airgid!",
        "金额": "Méid",
        "待审核": "Le hathbhreithniú",
        "审核成功": "Rath iniúchta",
        "审核退回": "Athbhreithniú ar ais",
        "请充值后观看视频！": "Féach an físeán tar éis an athluchtaithe!",
        "释放即可刷新": "Is féidir scaoileadh a athnuachan...",
        "加载中": "Loading...",
        "下拉即可刷新": "Is féidir le tarraingt síos athnuachan...",
    },
    setLang:{
        title: "Socraigh teanga"
    },
    lang:{
        zh: "中国银行",
        jp: "An tSeapáin",
        ara: "Teanga",
        en: "Béarla-Béarla",
        spa: "Spáinnis",
        it: "Iodáilis",
        de: "Gearmáinis",
        pt: "Portaingéilis",
        fra: "Fraincis",
        nl: "Ollainnis",
        id: "Indinéisis",
        hi: "Hiondúis",
        kor: "Cóiréis",
        tr: "Tuircis",
        swe: "An tSualainnis",
        ga: "Éire",
        ru: "Rúisis",
        vie: "Vítneam",
        th: "Téalainnis",
        est: "An Eastóin",
        be: "An Bhealarúis",
        bul: "An Bhulgáir",
        pl: "An Pholainn",
        ms: "Malaeis",
    }
}
export default Ga