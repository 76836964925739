const Tr = {
    login: {
        title: "Oturum açma", //登录
        username:"Lütfen bir kullanıcı adı girin",//请输入用户名
        password:"Lütfen bir şifre girin",//请输入密码
        forgetPaw:"Şifremi unuttum",//忘记密码
        registerNow:"Hesabınız yok mu? Şimdi kaydolun",//没有账户？马上注册
        doLogin:"Oturum açma",//登录

    },
    register:{
        title: "Kayıt", //注册
        username:"Lütfen kullanıcı adınızı girin (6-12 alfasayısal harf)",//请输入用户名(6-12个英文字母或数字)
        password:"Lütfen giriş şifrenizi girin (6-12 alfasayısal karakter)",//请输入登录密码(6-12个英文字母或数字)
        code:"Lütfen davet kodunu girin",//请输入邀请码
        agreement:"Hesap Açma Sözleşmesi'nin şartlarının farkındayım ve kabul ediyorum",//我已经知晓并同意"开户协议"各项条约
        chooseAgreement:"Lütfen aşağıdaki hesap açılış sözleşmesini işaretleyin!",//请勾选下方开户协议！
        doRegister:"Kayıt",//注册
    },
    footer:{
        home :"Ev",//首页
        game:"rezervasyon",//预约
        video:"Video",//视频
        mine:"Bu benim",//我的
    },
    game:{
        title:"Rezervasyon salonu",//预约大厅
        all:"tüm",//全部
    },
    choose:{
        title:"Bir cariye seçin",//选妃
        city:"Şehirlerin Tam Kitabı",//城市大全
        rule:"Her kullanıcının kişisel gizliliğini sağlamak için, müşteriler yalnızca resepsiyonist veya platformun kıdemli üyelerinin gerçek isim önerisi ile iletişime geçerek katılabilirler.",//本平台全网最真实外围+商务伴游+同城激情，为保证每个用户的个人隐私，客户仅限通过联系接待员或平台资深会员实名推荐才可加入。
        price:"Fiyat süreci",//价格流程
        resource:"Hangi kaynaklar kullanılabilir?",//有哪些资源?
        character:"İnternet ünlüleri, modeller, uçuş görevlileri, genç modeller, üniversite öğrencileri, sadece siz düşünemezsiniz ve bu platform olmadan yapamazsınız",//网红、模特、空姐、嫩模、大学生，只有您想不到，没有本平台办不到
        scope:"Hizmetin kapsamı nedir?",//服务范围?
        location:"Aynı şehirde ücretsiz bağlantı, ülkenin herhangi bir yerinde havadan, Çin'deki birinci ve ikinci kademe şehirlerde yerel ve üçüncü kademe şehirlerde de resepsiyonistle iletişime geçerek randevu alabilir.",//同城免费约炮，任意地点全国空降，国内一二线城市当地均有，三线城市也可通过联系接待员预约安排。
    },
    home:{
        recommend:"Önerilen görevler",//推荐任务
        more:"Daha fazlasını gör",//查看更多
        popularity:"Popülerlik sıralaması",//人气排行
        hot:"En iyi öneriler",//热门推荐
    },
    lottery:{
        jieguo: "Oylama sonucu",
        jiesu: "Bitiş",
        record:"Görev kaydı",//任务记录
        rule:"Oyun kuralları",//玩法规则
        prompt:"Oynanış ipuçları",//玩法提示
        gameplay:"Bahsinizi koymak için isteğe bağlı ve değer kalıplarından sayıları seçin",//从可选和值形态里面选择号码进行下注
        explain:"Kazanma talimatları",//中奖说明
        wprole:"Üç piyango numarasının toplam değeri 11 ~ 18 büyüktür; 3 ~ 10'un toplam değeri küçüktür;",//三个开奖号码总和值11~18 为大;总和值3~ 10为小;
        example:"Bahis örnekleri",//投注范例
        option:"Bahis şeması: küçük Çekiliş numarası: 123, yani orta ve küçük",//投注方案：小 开奖号码：123,即中小
        task:"Bilet",//任务单
        money:"Kullanılabilir bakiye",//可用余额
        submit:"Gönder",//提交
        selection:"Geçerli seçim numarası",//当前选号
        perNote:"Bahis başına tutar",//每注金额
        inputMoney:"Lütfen tutarı girin",//请输入金额
        total:"Toplam",//总共
        note:"Toplam",//总共
        statistics:"toplam",//合计
        empty:"Siparişi boşaltma",//清空订单
        verify:"Gönderimi onaylayın",//确认提交
        issue:"Sorun numarası",//期号
        num:"Numara çekme",//开奖号码
        big:"büyük",//大
        small:"küçük",//小
        single:"tek",//单
        double:"çift",//双
        unselected:"Seçili değil",//未选择
        drawLottery:"Çekiliş başarılı, dönem numarası:",//开奖成功，期号：
        kefu:"Görevi talep etmek için lütfen yöneticiye başvurun!",//请联系管理员领取该任务!
    },
    mine:{
        recharge:"Şarj",//充值
        withdraw:"Para çekme",//提现
        myMoney:"Cüzdanım",//我的钱包
        detail:"ayrıntı",//详情
        balance:"denge",//余额
        finance:"Kişisel raporlar",//个人报表
        order:"Hesap Detayları",//账户明细
        record:"Görev kaydı",//任务记录
        my:"Kişisel Merkez",//个人中心
        notice:"Bilgi Bültenleri",//信息公告
        service:"Online müşteri hizmetleri",//在线客服
        score: "Integral",
    },
    setting:{
        title:"Hazırlamak",//设置
        basic:"Temel bilgi ayarları",//基本信息设置
        password:"Giriş şifresi",//登录密码
        capital:"Para şifresi",//资金密码
        lang:"Dil",//语言
        quit:"Oturumu kapat",//退出登录
    },
    setPayPassword: {
        title:"Fonlar için parola belirleme",//设置资金密码
        opw:"Lütfen fon şifresini girin",//请输入资金密码
        tpw:"Lütfen fon şifresini tekrar girin",//请再次输入资金密码
    },
    gameRecord:{
        title:"Görev kaydı",//任务记录
        amountBet:"Bahis miktarı",//下注金额
        create:"Sipariş zamanı",//下单时间
        update:"Yerleşim zamanı",//结算时间
    },
    video:{
        title:"Video Sinema",//视频影院
        play:"Oynamak",//播放
    },
    playVideo:{
        play: "Oynanan Zamanlar",//次播放
    },
    setname:{
        title:"Gerçek adınızı değiştirme",//修改真实姓名
        name:"ad",//姓名
        truename:"Lütfen gerçek adınızı girin",//请输入真实姓名
        hint:"Hesabınızın güvenliği için gerçek adınızın bağlı banka kartındaki adla eşleşmesi gerekir",//为了您账户安全,真实姓名需要与绑定银行卡姓名一致
    },
    setsex:{
        title:"Cinsiyet değişikliği",//性别修改
    },
    bindCard:{
        title:"Toplama kartını doldurun",//填写收款卡
        msg:"Lütfen ödeme kartı bilgilerinizi girin",//请输入您的收款卡信息
        hint:"Sevgili kullanıcı, fonlarınızın güvenliğini korumak için lütfen gerçek adınızı bağlayın ve bir para çekme şifresi belirleyin, eğer ad hesap açılış adıyla tutarsızsa, para çekemezsiniz",//尊敬的用户,为了保障您的资金安全,请您绑定您的真实姓名和设置取款密码,如果姓名与开户名不一致,将无法取款
        card: "Hesap numarası (normal)",
        truecard: "Hesap numarasını girin (normal)",
        name: "Hesap adı (takma ad)",
        truename: "Hesap adını girin (takma ad)",
        financial_institution: "Finansal kurum adı",
        truefinancial_institution: "Finansal kurumun adını girin",
        branch: "Şube adı",
        truebranch: "Şube adını girin",
        branch_number: "Şube Numarası",
        truebranch_number: "Şube numarasını girin"
    },
    setbank:{
        title:"Koleksiyon kartı bilgileri",//收款卡信息
        bank:"Banka kartı ekleme",//添加银行卡
        hint:"İpuçları: Lütfen büyük ticari bankalara bağlayın, değiştirmeniz gerekiyorsa, lütfen çevrimiçi müşteri hizmetleriyle iletişime geçin",//提示:请绑定大型商业银行,如需修改,请您联系在线客服
    },
    setLoginPassword:{
        title:"Oturum açma parolasını değiştirme",//修改登录密码
        old_password:"Eski şifre",//旧密码
        old_password_p:"Lütfen eski şifrenizi girin",//请输入旧密码
        o_new_password:"Yeni şifre",//新密码
        o_new_password_p:"Lütfen yeni bir şifre girin",//请输入新密码
        t_new_password_p:"Lütfen yeni şifreyi tekrar girin",//请再次输入新密码
    },
    infomation:{
        title:"Temel bilgiler",//基本信息
        head:"avatar",//头像
        select:"Bir avatar seçin",//选择头像
        name:"Gerçek isim",//真实姓名
        sex:"cinsiyet",//性别
        man:"adam",//男
        girl:"kadın",//女
        unknown:"Bilinmeyen",//未知
        binding:"Bağlayıcı bilgiler",//绑定信息
    },
    withdraw: {
        title:"Para Çekme Merkezi",//提现中心
        record:"Para çekme geçmişi",//提现记录
        money:"Para çekme miktarı",//提现金额
        all:"tüm",//全部
        min:"Tek İşlem Limiti: Minimum",//单笔限额：最低
        max:"Yüksek",//最高
        num:"Para çekme sayısı: Bir günde maksimum para çekme",//提现次数：一天最高提现
        hint:"Varış zamanı: Genel olarak, varış süresi yaklaşık 5 dakikadır ve en hızlı varış süresi 2 dakikadır",//到账时间：一般到账时间在5分钟左右，最快2分钟内到账
        quota:"Sınırın tanımı",//限额说明
        balance:"denge",//余额
        withdraw:"Şimdi nakde çevirin",//马上提现
    },
    withdrawRecord:{
        desc:"Göstermek",//说明
        create:"Gönderim süresi",//提交时间
        update:"İnceleme zamanı",//审核时间
    },
    personalreport:{
        title:"Kişisel raporlar",//个人报表
        profit:"Kâr tutarı",//盈利金额
        formula:"Kar hesaplama formülü: Kazanç miktarı - görev tutarı",//盈利计算公式 : 中奖金额 - 任务金额
        task:"Görev miktarı",//任务金额
        recharge:"Kontör yükleme tutarı",//充值金额
        withdrawal:"Para çekme miktarı",//提现金额
        win_money:"Kazanan miktar",//中奖金额
    },
    service: {
        title:"Online müşteri hizmetleri",//在线客服
        relation:"temas",//联系
        hint:"7*24 saat size gönülden hizmet etmek için",//全天7 * 24小时竭诚为您服务
    },
    msg:{
        "次":"kere",
        "用户不存在！":"Kullanıcı yok!",//用户不存在！
        "密码错误！":"Yanlış şifre!",
        "登录成功！":"Giriş başarılı oldu!",
        "邀请码不存在！":"Davet kodu mevcut değil!",
        "用户名位数错误！":"Kullanıcı adının basamak sayısı yanlış!",
        "密码位数错误！":"Şifrede yanlış rakam sayısı!",
        "用户名不能存在中文！":"Kullanıcı adı Çince olamaz!",
        "邀请码不能为空！":"Davet kodu boş bırakılamaz!",
        "注册成功！":"Kayıt başarılı!",
        "注册失败！":"Kayıt başarısız oldu!",
        "用户已存在":"Kullanıcı zaten var",
        "刷新成功！":"Yenileme başarılı!",
        "请联系顾问或接待员":"Lütfen bir danışman veya resepsiyonist ile iletişime geçin",
        "金额错误！":"Yanlış miktar!",
        "请选号！":"Lütfen numarayı seçiniz!",
        "请填写金额!":"Lütfen tutarı doldurunuz!",
        "余额不足，请联系客服充值！":"Bakiye yetersiz, şarj etmek için lütfen müşteri hizmetleriyle iletişime geçin!",
        "没有更多了":"Artık yok",
        "没有数据":"Veri yok",
        "余额不足！":"Denge yetersiz!",
        "扣款失败！":"Ters ibraz başarısız oldu!",
        "下注异常！":"Bahis anormal!",
        "投注成功！":"Bahis Başarısı!",
        "参数异常！":"Parametre anormal!",
        "获取中":"İçeri gir ...",
        "请完成任务单后进入":"Lütfen görev listesini doldurun ve girin",
        "请联系客服充值":"Lütfen kontör yüklemek için müşteri hizmetleriyle iletişime geçin",
        "请设置收款卡!":"Bir koleksiyon kartı oluşturun!",
        "功能已禁用!":"Özellik devre dışı!",
        "账号下线":"Hesap çevrimdışı",
        "登录/注册":"Giriş/Kayıt",
        "登录可享受更多服务！":"Daha fazla hizmetin tadını çıkarmak için giriş yapın!",
        "未设置":"Ayarlanmadı",
        "已设置":"Ayarlamak",
        "提交":"Gönder",
        "确定":"Emin misiniz",
        "请填写完整":"Lütfen eksiksiz doldurun",
        "两次密码不一致！":"Şifreler iki kez tutarsız!",
        "设置提现密码成功！":"Para çekme şifresini başarıyla ayarlayın!",
        "提现密码已设置，需要修改请联系客服":"Para çekme şifresi ayarlandı, değiştirmeniz gerekirse lütfen müşteri hizmetleriyle iletişime geçin",
        "已绑定":"Sınır",
        "无":"değil",
        "更换头像成功！":"Avatar değiştirme başarılı!",
        "更新头像失败！":"Avatar güncellenemedi!",
        "请勿重复设置！":"Ayarları tekrarlamayın!",
        "设置姓名成功！":"İsim başarıyla ayarlandı!",
        "设置姓名失败！":"İsim ayarlanamadı!",
        "设置性别成功！":"Cinsiyeti başarıyla ayarlayın!",
        "设置性别失败！":"Set gender başarısız oldu!",
        "请设置姓名后再绑定银行卡！":"Banka kartınızı bağlamadan önce adınızı ayarlayın!",
        "请设置提现密码后再绑定银行卡！":"Lütfen bir banka kartı bağlamadan önce bir para çekme şifresi belirleyin!",
        "确认绑卡":"Bağlamayı onaylayın",
        "请输入银行卡号！":"Lütfen banka kartı numaranızı giriniz!",
        "请输入银行名称！":"Lütfen banka adını giriniz!",
        "设置银行卡成功！":"Banka kartınızı başarıyla kurun!",
        "设置银行卡失败！":"Banka kartı ayarlama başarısız oldu!",
        "旧密码错误":"Eski parola yanlış",
        "修改密码成功！":"Şifreyi başarıyla değiştirin!",
        "修改密码失败！":"Şifre değiştirilemedi!",
        "请填写正确的金额":"Lütfen doğru miktarı doldurun",
        "提现金额不能少于：":"Para çekme miktarı aşağıdakilerden az olmamalıdır:",
        "提现金额不能大于：":"Para çekme miktarı aşağıdakilerden daha büyük olamaz:",
        "当日提现次数已用完":"O günkü ödemelerin sayısı tükendi",
        "提现成功！":"Para çekme işlemi başarılı!",
        "提现失败！":"Para çekme başarısız oldu!",
        "金额":"miktar",
        "待审核":"İncelenecek",
        "审核成功":"İnceleme başarılı",
        "审核退回":"Geri dönen yorumları inceleme",
        "请充值后观看视频！":"Lütfen doldurun ve videoyu izleyin!",
        "释放即可刷新":"Yenilemek için bırakın...",
        "加载中":"Yükleme...",
        "下拉即可刷新": "Yenilemek için aşağı çek...",
    },
    setLang:{
        title:"Dili ayarlama"
    },
    profile: {
        portrait: "Fotoğraf",
        video: "Video",
        jianjie:" Kişisel profil",
        liji: "Hemen randevu alın.",
        chongdan: "şarj aralığı",
        fd: "Soylu kadın.",
        yuyue: "Randevu",

        archives: "Kız Dosyası",
        age: "Yaş",
        height: "Yükseklik",
        weight: "Ağırlık",
        cup: "Bardak",
        city: "Şehir",
        area: "Hizmet Alanı",
        item: "Hizmet öğeleri"
    },
    lang:{
        "zh": "Çince",
        "jp": "Japonya",
        "ara": "Dil",
        "en": "İngilizce",
        "spa": "İspanyolca",
        "it": "İtalyanca",
        "de": "Almanca",
        "pt": "Portekizce",
        "fra": "Fransızca",
        "nl": "Hollandaca",
        "id": "Endonezyaca",
        "hi": "Hintçe",
        "kor": "Korece",
        "tr": "Türkçe",
        "swe": "İsveççe",
        "ga": "İrlandaca",
        "ru": "Rusça",
        "vie": "Vietnamca",
        th: "Tayca",
        est: "Estonya Dili",
        be: "Belarusça",
        bul: "Bulgarca",
        pl: "Lehçe",
        ms: "Malayca",
    }
}
export default  Tr