<template>
  <div class="container page">
    <van-nav-bar :title="$t('setLang.title')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>
    <div class="sex">
      <van-radio-group v-model="radio">
        <div v-for="(item,keys) in list" :key="keys" class="item van-hairline--bottom" @click="chooesSex(keys)">
          <van-radio :name="keys">{{ $t('lang.'+keys) }}</van-radio>
        </div>
      </van-radio-group>

    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      radio:"",

      list:{
        "zh": "中文 ",
        "jp": "日本 ",
        "ara": "阿拉伯语 ",
        "en": "英语 ",
        "spa": "西班牙语 ",
        "it": "意大利语 ",
        "de": "德语 ",
        "pt": "葡萄牙语 ",
        "fra": "法语",
        "nl": "荷兰语",
        "id": "印尼语",
        "hi": "印地语",
        "kor": "韩语",
        "tr": "土耳其语",
        "swe": "瑞典语",
        "ga": "爱尔兰语",
        "ru": "俄语",
        "vie": "越南语",
        "th": "泰语",
        est: "爱沙尼亚语",
        be: "白俄罗斯语",
        bul: "保加利亚语",
        pl: "波兰语",
        ms: "马来语",
      }
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    chooesSex(lang){
      localStorage.setItem('lang',lang);
      this.back();
    },
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }
    this.radio = localStorage.getItem('lang');
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.container .sex{
  background-color: #fff;
  padding: 0 40px;
}
.container .sex .item{
  font-size: 35px;
  line-height: 50px;
  padding: 30px 0;
}
::v-deep .van-radio__label {
  line-height: 50px;
  margin-left: 30px;
}
::v-deep .van-radio__icon {
  font-size: 30px;
}
::v-deep .van-radio__icon--checked .van-icon {
  color: #fff;
  border-color: rgb(126, 86, 120);
  background-color: rgb(126, 86, 120);
}
.container .van-hairline--bottom::after {
  border-bottom-width: 3px;
}
</style>
