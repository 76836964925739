const En = {
    login: {
        title: "Login ",
        username: "Please enter a user name ",
        password: "Please enter your password ",
        forgetPaw: "Forgot password ",
        registerNow: "No account? Register immediately ",
        doLogin: "Login ",

    },
    profile: {
        jieguo: "Vote ",
        jiesu: "end ",
        portrait: "portray a person",
        video: "Video ",
        jianjie: "Personal Profile ",
        liji: "Make an appointment now ",
        chongdan: "Charging range ",
        fd: "Lady ",
        yuyue: "Reservation ",
        archives: "Girl file ",
        age: "Age ",
        height: "Height ",
        weight: "Weight ",
        cup: "Cup size ",
        city: "City ",
        area: "Service area ",
        item: "Service items "
    },
    register:{
        title: "Registration ",
        username: "Please enter the user name (6-12 English letters or numbers) ",
        password: "Please enter your login password (6-12 English letters or numbers) ",
        code: "Please enter the invitation code ",
        agreement: "I have known and agreed to the account opening agreement and treaties ",
        chooseAgreement: "Please check the account opening agreement below! ",
        doRegister: "Registration ",

    },
    footer:{
        home : "Home page ",
        game: "Reservation ",
        video: "Video ",
        mine: "mine ",

    },
    game:{
        title: "Reservation hall ",
        all: "All ",

    },
    choose:{
        title: "Choose a concubine ",
        city: "City Encyclopedia ",
        rule: "The platform is the most authentic in the whole network + business escort + passion in the same city. In order to ensure the personal privacy of each user, customers can only join by contacting the receptionist or the real name recommendation of senior members of the platform ",
        price: "Price flow ",
        resource: "What resources are there? ",
        character: "Online celebrity model stewardess tender model college students only you can't think of without Ben Ping ",
        scope: "Scope of service? ",
        location: "Free gun in the same city. Any place is airborne in the first and second tier cities in China. There are third-tier cities in the local area. You can also make an appointment by contacting the receptionist ",

    },
    home:{
        recommend: "Recommended task ",
        more: "See more ",
        popularity: "Popularity ranking ",
        hot: "Popular recommendation ",

    },
    lottery:{
        record: "Task record ",
        rule: "Rules of play ",
        prompt: "Play tips ",
        gameplay: "Select a number from the optional sum form to bet ",
        explain: "Winning description ",
        wprole: "The total value of the three lottery numbers is 11 ~ 18; The sum value of 3 ~ 10 is small; ",
        example: "Betting example ",
        option: "Betting plan: Small lottery number: 123, that is, small and medium-sized ",
        task: "Task list ",
        money: "Available balance ",
        submit: "Submit ",
        selection: "Current selection ",
        perNote: "Amount per note ",
        inputMoney: "Please enter an amount ",
        total: "Total ",
        note: "Total ",
        statistics: "Total ",
        empty: "Empty an order ",
        verify: "Confirmation of submission ",
        issue: "Issue number ",
        num: "Lottery number ",
        big: "Big ",
        small: "small ",
        single: "single ",
        double: "Double ",
        unselected: "Not selected ",
        drawLottery: "Successful lottery period number: ",
        kefu: "Please contact the administrator for this task! ",

    },
    mine:{
        recharge: "Recharge ",
        withdraw: "Withdrawal ",
        myMoney: "My wallet ",
        detail: "Details ",
        balance: "Balance ",
        finance: "Personal report ",
        order: "Account details ",
        record: "Task record ",
        my: "Individual center ",
        notice: "Information bulletin ",
        service: "Online customer service ",
        score: "integral ",
        xy: "credit score",
    },
    setting:{
        title: "Settings ",
        basic: "Basic information settings ",
        password: "Login password ",
        capital: "Fund password ",
        lang: "language ",
        quit: "Log out of the login ",

    },
    setPayPassword: {
        title: "Set the fund password ",
        opw: "Please enter the fund password ",
        tpw: "Please enter the fund password again ",

    },
    gameRecord:{
        title: "Task record ",
        amountBet: "Bet amount ",
        create: "Ordering time ",
        update: "Settlement time ",

    },
    video:{
        title: "Video cinema ",
        play: "Play ",

    },
    playVideo:{
        play: "Secondary play ",

    },
    setname:{
        title: "Modify your real name ",
        name: "Name ",
        truename: "Please enter your real name ",
        hint: "For the security of your account, the real name needs to be consistent with the name of the bound bank card ",

    },
    setsex:{
        title: "Gender modification ",

    },
    bindCard:{
        title: "Fill in the collection card ",
        msg: "Please enter your collection card information ",
        hint: "Dear users, in order to ensure the safety of your funds, please bind your real name and set the withdrawal password. If the name is inconsistent with the account opening name, you will not be able to withdraw money ",
        card: "Account number (normal) ",
        truecard: "Enter account number (normal) ",
        name: "Account name (real name) ",
        truename: "Enter the account name (real name) ",
        financial_institution: "Name of financial institution ",
        truefinancial_institution: "Enter the name of a financial institution ",
        branch: "Branch name ",
        truebranch: "Enter a branch name ",
        branch_number: "Branch number ",
        truebranch_number: "Enter a branch number "
    },
    setbank:{
        title: "Collection card information ",
        bank: "Add a bank card ",
        hint: "Tip: Please bind to a large commercial bank. If you need to modify it, please contact online customer service ",

    },
    setLoginPassword:{
        title: "Modify login password ",
        old_password: "Old password ",
        old_password_p: "Please enter your old password ",
        o_new_password: "New password ",
        o_new_password_p: "Please enter a new password ",
        t_new_password_p: "Please enter the new password again ",

    },
    infomation:{
        title: "Basic information ",
        head: "Head portrait ",
        select: "Select an avatar ",
        name: "Real name ",
        sex: "Gender ",
        man: "Male ",
        girl: "female ",
        unknown: "Unknown ",
        binding: "Binding information ",

    },
    withdraw: {
        title: "Cash withdrawal center ",
        record: "Withdrawal record ",
        money: "Cash withdrawal amount ",
        all: "All ",
        min: "Single limit: minimum ",
        max: "",
        num: "Cash withdrawal: cash withdrawal in one day ",
        hint: "Arrival time: Generally, the arrival time is about 5 minutes, and the fastest arrival time is 2 minutes ",
        quota: "Quota description ",
        balance: "Balance ",
        withdraw: "Withdraw cash immediately ",

    },
    withdrawRecord:{
        desc: "Description ",
        create: "Time of submission ",
        update: "Audit time ",

    },
    personalreport:{
        title: "Personal report ",
        profit: "Amount of profit ",
        formula: "Profit calculation formula: winning amount-task amount ",
        task: "Task amount ",
        recharge: "Recharge amount ",
        withdrawal: "Cash withdrawal amount ",
        win_money: "Winning amount ",

    },
    service: {
        title: "Online customer service ",
        relation: "contact ",
        hint: "Serve you wholeheartedly 7 * 24 hours a day ",

    },
    msg:{
        "次": "times ",
        "用户不存在！": "User does not exist! ",
        "密码错误！": "Wrong password! ",
        "登录成功！": "Login successful! ",
        "邀请码不存在！": "Invitation code does not exist! ",
        "用户名位数错误！": "User name number error! ",
        "密码位数错误！": "Wrong number of passwords! ",
        "用户名不能存在中文！": "User name cannot exist in Chinese! ",
        "邀请码不能为空！": "Invitation code cannot be empty! ",
        "注册成功！": "Successful registration! ",
        "注册失败！": "Registration failed! ",
        "用户已存在": "User already exists ",
        "刷新成功！": "Refresh successfully! ",
        "请联系顾问或接待员": "Please contact your consultant or receptionist ",
        "金额错误！": "Wrong amount! ",
        "请选号！": "Please choose the number! ",
        "请填写金额!": "Please fill in the amount! ",
        "余额不足，请联系客服充值！": "If the balance is insufficient, please contact customer service to recharge! ",
        "没有更多了": "There is no more ",
        "没有数据": "No data ",
        "余额不足！": "The balance is insufficient! ",
        "扣款失败！": "Deduction failed! ",
        "下注异常！": "Abnormal betting! ",
        "投注成功！": "Betting success! ",
        "参数异常！": "Parameter exception! ",
        "获取中": "Getting … ",
        "请完成任务单后进入": "Please complete the task list ",
        "请联系客服充值": "Please contact customer service to recharge ",
        "请设置收款卡!": "Please set up the collection card! ",
        "功能已禁用!": "Feature is disabled! ",
        "账号下线": "Account offline ",
        "登录/注册": "Login/Register ",
        "登录可享受更多服务！": "Log in to enjoy more services! ",
        "未设置": "Not set ",
        "已设置": "Has been set ",
        "提交": "Submit ",
        "确定": "Determine ",
        "请填写完整": "Please complete it ",
        "两次密码不一致！": "The passwords are inconsistent twice! ",
        "设置提现密码成功！": "Setting cash withdrawal password successfully! ",
        "提现密码已设置，需要修改请联系客服": "The cash withdrawal password has been set and needs to be modified. Please contact customer service ",
        "已绑定": "Bound ",
        "无": "none ",
        "更换头像成功！": "T replaced avatar successfully! ",
        "更新头像失败！": "Failed to update avatar! ",
        "请勿重复设置！": "Do not repeat settings! ",
        "设置姓名成功！": "Set the name successfully! ",
        "设置姓名失败！": "Failed to set name! ",
        "设置性别成功！": "Set gender success! ",
        "设置性别失败！": "Failed to set gender! ",
        "请设置姓名后再绑定银行卡！": "Please set your name before binding your bank card! ",
        "请设置提现密码后再绑定银行卡！": "Please set the cash withdrawal password before binding the bank card! ",
        "确认绑卡": "Confirm card binding ",
        "请输入银行卡号！": "Please enter the bank! ",
        "请输入银行名称！": "Please enter the bank name! ",
        "设置银行卡成功！": "Set up the bank card successfully! ",
        "设置银行卡失败！": "Setting up bank card failed! ",
        "旧密码错误": "Old password error ",
        "修改密码成功！": "The password was changed successfully! ",
        "修改密码失败！": "Password change failed! ",
        "请填写正确的金额": "Please fill in the correct amount ",
        "提现金额不能少于：": "Cash withdrawal amount cannot be less than: ",
        "提现金额不能大于：": "Cash withdrawal amount cannot be greater than: ",
        "当日提现次数已用完": "The cash withdrawal on that day has been used up ",
        "提现成功！": "Cash withdrawal succeeded! ",
        "提现失败！": "Cash withdrawal failed! ",
        "金额": "Amount ",
        "待审核": "Pending review ",
        "审核成功": "Successful audit ",
        "审核退回": "Audit return ",
        "请充值后观看视频！": "Please recharge and watch the video! ",
        "释放即可刷新": "Release to refresh... ",
        "加载中": "Loading... ",
        "下拉即可刷新": "Drop down to refresh... ",
    },
    setLang:{
        title: "Setting language "
    },
    lang:{
        zh: "Chinese ",
        jp: "Japan ",
        ara: "English ",
        en: "English ",
        spa: "Spanish ",
        it: "Italian ",
        de: "German ",
        pt: "Portuguese ",
        fra: "French ",
        nl: "Dutch ",
        id: "Indonesian ",
        hi: "Hindi ",
        kor: "Korean ",
        tr: "Turkish ",
        swe: "Swedish ",
        ga: "Irish ",
        ru: "Russian ",
        vie: "Vietnamese ",
        th: "Thai ",
        est: "Estonian ",
        be: "Belarusian ",
        bul: "Bulgarian ",
        pl: "Polish ",
        ms: "Malay ",
    }
}
export default En