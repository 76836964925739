const Hi = {
    login: {
        title: "लॉगइन",
        username: "कृपया एक उपयोक्ता नाम भरें",
        password: "कृपया पासवर्ड भरें",
        forgetPaw: "कूटशब्द भूल गए",
        registerNow: "कोई खाता नहीं? अभी रजिस्टर करें",
        doLogin: "लॉगइन",

    },
    profile: {
        portrait: "फोटोग्राफी",
        video: "वीडियो",
        jianjie: "व्यक्तिगत प्रोफ़ाइल",
        liji: "अभी अप्वाइंटमेंट लें",
        chongdan: "चार्ज रेंज",
        fd: "नारी",
        yuyue: "अपॉइंटमेंट",
        archives: "लड़की फ़ाइल",
        age: "आयु",
        height: "कद",
        weight: "वज़न",
        cup: "कफ",
        city: "नगरी",
        area: "सेवा क्षेत्र",
        item: "सेवा मद"
    },
    register:{
        title: "पंजीकरण",
        username: "कृपया उपयोगकर्ता नाम (6-12 अंग्रेजी अक्षर या संख्या) दर्ज करें",
        password: "कृपया अपना लॉगिन पासवर्ड (6-12 अंग्रेजी अक्षर या संख्या) दर्ज करें",
        code: "कृपया निमंत्रण कोड दर्ज करें",
        agreement: "मैं खाता खोलने के समझौते की संधियों को जानता हूं और उनसे सहमत हूं",
        chooseAgreement: "कृपया नीचे खाता खोलने के समझौते की जाँच करें!",
        doRegister: "पंजीकरण",

    },
    footer:{
        home : "मुखपृष्ठ",
        game: "अपॉइंटमेंट",
        video: "वीडियो",
        mine: "मेरा",

    },
    game:{
        title: "अपॉइंटमेंट हॉल",
        all: "सब",

    },
    choose:{
        title: "चुनी हुई राजकुमारी",
        city: "सिटी विश्वकोश",
        rule: "इस प्लेटफ़ॉर्म का पूरा नेटवर्क एक ही शहर में सबसे प्रामाणिक + बिजनेस एस्कॉर्ट + जुनून है। प्रत्येक उपयोगकर्ता की व्यक्तिगत गोपनीयता सुनिश्चित करने के लिए, ग्राहक केवल रिसेप्शनिस्ट या प्लेटफ़ॉर्म के वरिष्ठ सदस्यों से संपर्क करके शामिल हो सकते हैं।",
        price: "मूल्य प्रवाह",
        resource: "क्या संसाधन उपलब्ध हैं?",
        character: "नेट रेड, मॉडल, होस्टेस, टेंडर मॉडल, कॉलेज के छात्र, केवल आप इसके बारे में सोच भी नहीं सकते,",
        scope: "सेवाओं का दायरा?",
        location: "एक ही शहर में नि: शुल्क तोपखाने नियुक्तियां, किसी भी स्थान पर देश भर में एयरबोर्न, चीन में पहले और दूसरे स्तर के शहरों में उपलब्ध हैं, और तीसरे स्तर के शहर रिसेप्शनिस्ट से संपर्क करके नियुक्तियां भी कर सकते हैं।",

    },
    home:{
        recommend: "अनुशंसित कार्य",
        more: "अधिक देखें",
        popularity: "लोकप्रियता",
        hot: "लोकप्रिय अनुशंसाएँ",

    },
    lottery:{
        jieguo: "मतदान का परिणाम",
        jiesu: "अन्त",
        record: "मिशन रिकार्ड",
        rule: "गेमप्ले नियम",
        prompt: "गेमप्ले युक्तियाँ",
        gameplay: "सट्टेबाजी के लिए वैकल्पिक और मूल्य पैटर्न से एक संख्या का चयन करें",
        explain: "विजेता विवरण",
        wprole: "तीन ड्रॉ नंबरों का कुल मूल्य 11 से 18 तक है; 3 से 10 का योग मूल्य छोटा है;",
        example: "सट्टेबाजी के उदाहरण",
        option: "सट्टेबाजी योजना: छोटी ड्रा संख्या: 123, यानी छोटा और मध्यम",
        task: "कार्य सूची",
        money: "उपलब्ध शेष",
        submit: "जमा करना",
        selection: "वर्तमान चयन संख्या",
        perNote: "प्रति इंजेक्शन राशि",
        inputMoney: "कृपया एक राशि दर्ज करें",
        total: "कुल",
        note: "कुल",
        statistics: "कुल",
        empty: "आदेश खाली करें",
        verify: "सबमिशन की पुष्टि करें",
        issue: "अवधि संख्या",
        num: "ड्रा क्रमांक",
        big: "बड़ा",
        small: "छोटा-सा",
        single: "एकमुश्त",
        double: "दुगना",
        unselected: "चयनित नहीं",
        drawLottery: "ड्रा सफल, अंक संख्या:",
        kefu: "इस कार्य के लिए व्यवस्थापक से संपर्क करें!",

    },
    mine:{
        recharge: "रिचार्ज",
        withdraw: "आहरण",
        myMoney: "मेरा बटुआ",
        detail: "ब्यौरा",
        balance: "शेष राशि",
        finance: "व्यक्तिगत विवरण",
        order: "खाता विवरण",
        record: "मिशन रिकार्ड",
        my: "व्यक्तिगत केंद्र",
        notice: "सूचना घोषणा",
        service: "ऑनलाइन ग्राहक सेवा",
        score: "अन्तर्ग्रही",
    },
    setting:{
        title: "सेटअप",
        basic: "बुनियादी जानकारी सेटिंग्स",
        password: "लॉगइन पासवर्ड",
        capital: "निधि पासवर्ड",
        lang: "भाषा",
        quit: "लॉगइन से बाहर निकलें",

    },
    setPayPassword: {
        title: "फंड पासवर्ड सेट करें",
        opw: "कृपया धन पासवर्ड दर्ज करें",
        tpw: "कृपया धन पासवर्ड फिर से दर्ज करें",

    },
    gameRecord:{
        title: "मिशन रिकार्ड",
        amountBet: "शर्त राशि",
        create: "आदेश देने का समय",
        update: "निपटान समय",

    },
    video:{
        title: "वीडियो थियेटर",
        play: "बजाना",

    },
    playVideo:{
        play: "दूसरा प्लेबैक",

    },
    setname:{
        title: "वास्तविक नाम परिवर्धित करें",
        name: "नाम",
        truename: "कृपया वास्तविक नाम भरें",
        hint: "आपके खाते की सुरक्षा के लिए, वास्तविक नाम बाध्य बैंक कार्ड के नाम से मेल खाना चाहिए",

    },
    setsex:{
        title: "लिंग संशोधन",

    },
    bindCard:{
        title: "रसीद कार्ड भरें",
        msg: "कृपया अपना कैश कार्ड जानकारी दर्ज करें",
        hint: "प्रिय उपयोगकर्ता, अपने धन की सुरक्षा सुनिश्चित करने के लिए, कृपया अपना वास्तविक नाम बांधें और निकासी पासवर्ड सेट करें। यदि नाम खाता खोलने के नाम से मेल नहीं खाता है, तो आप पैसे नहीं निकाल पाएंगे।",
        card: "खाता संख्या (सामान्य)",
        truecard: "खाता संख्या दर्ज करें (सामान्य)",
        name: "खाता नाम (वास्तविक नाम)",
        truename: "खाता नाम (वास्तविक नाम) दर्ज करें",
        financial_institution: "वित्तीय संस्थान का नाम",
        truefinancial_institution: "वित्तीय संस्थान का नाम दर्ज करें",
        branch: "शाखा नाम",
        truebranch: "शाखा नाम भरें",
        branch_number: "शाखा संख्या",
        truebranch_number: "शाखा संख्या दर्ज करें"
    },
    setbank:{
        title: "रसीद कार्ड की जानकारी",
        bank: "बैंक कार्ड जोड़ें",
        hint: "युक्ति: कृपया एक बड़े वाणिज्यिक बैंक से जुड़ें। यदि आपको संशोधन की आवश्यकता है, तो कृपया ऑनलाइन ग्राहक सेवा से संपर्क करें",

    },
    setLoginPassword:{
        title: "लॉगइन पासवर्ड बदलें",
        old_password: "पुराना कूटशब्द",
        old_password_p: "कृपया पुराना पासवर्ड दर्ज करें",
        o_new_password: "नया कूटशब्द",
        o_new_password_p: "कृपया एक नया पासवर्ड दर्ज करें",
        t_new_password_p: "कृपया नया पासवर्ड फिर से दर्ज करें",

    },
    infomation:{
        title: "बुनियादी जानकारी",
        head: "अवतारः",
        select: "अवतार चुनें",
        name: "असली नाम",
        sex: "लिंग",
        man: "पुरूष",
        girl: "नारी",
        unknown: "अनजान",
        binding: "बाइंडिंग जानकारी",

    },
    withdraw: {
        title: "निकासी केंद्र",
        record: "आहरण अभिलेख",
        money: "आहरण राशि",
        all: "सब",
        min: "एकल सीमा: न्यूनतम",
        max: "",
        num: "आहरण: एक दिन आहरण",
        hint: "आगमन का समय: आम तौर पर आगमन का समय लगभग 5 मिनट होता है, और सबसे तेज़ आगमन 2 मिनट के भीतर होता है",
        quota: "सीमा विवरण",
        balance: "शेष राशि",
        withdraw: "तत्काल आहरण",

    },
    withdrawRecord:{
        desc: "वर्णन",
        create: "जमा करने का समय",
        update: "लेखापरीक्षा समय",

    },
    personalreport:{
        title: "व्यक्तिगत विवरण",
        profit: "लाभ राशि",
        formula: "लाभ गणना सूत्र: विजेता राशि-कार्य राशि",
        task: "कार्य राशि",
        recharge: "पुनःपूर्ति राशि",
        withdrawal: "आहरण राशि",
        win_money: "विजेता राशि",

    },
    service: {
        title: "ऑनलाइन ग्राहक सेवा",
        relation: "सम्पर्क",
        hint: "दिन में 7 * 24 घंटे आपकी सेवा करने के लिए समर्पित",

    },
    msg:{
        "次": "नन्हा",
        "用户不存在！": "उपयोगकर्ता मौजूद नहीं है!",
        "密码错误！": "गलत पासवर्ड!",
        "登录成功！": "लॉगिन सफल!",
        "邀请码不存在！": "निमंत्रण कोड मौजूद नहीं है!",
        "用户名位数错误！": "गलत उपयोगकर्ता नाम अंक!",
        "密码位数错误！": "गलत पासवर्ड अंक!",
        "用户名不能存在中文！": "उपयोगकर्ता नाम चीनी में मौजूद नहीं हो सकता है!",
        "邀请码不能为空！": "निमंत्रण कोड खाली नहीं हो सकता है!",
        "注册成功！": "सफल पंजीकरण!",
        "注册失败！": "पंजीकरण विफल!",
        "用户已存在": "उपयोक्ता पहले से मौजूद है",
        "刷新成功！": "रिफ्रेश सफल!",
        "请联系顾问或接待员": "सलाहकार या रिसेप्शनिस्ट से संपर्क करें",
        "金额错误！": "राशि गलत है!",
        "请选号！": "कृपया नंबर चुनें!",
        "请填写金额!": "कृपया राशि भरें!",
        "余额不足，请联系客服充值！": "शेष राशि अपर्याप्त है, कृपया रिचार्ज करने के लिए ग्राहक सेवा से संपर्क करें!",
        "没有更多了": "और कुछ नहीं",
        "没有数据": "कोई डेटा नहीं",
        "余额不足！": "अपर्याप्त संतुलन!",
        "扣款失败！": "कटौती विफल!",
        "下注异常！": "सट्टेबाजी असामान्य है!",
        "投注成功！": "सट्टेबाजी सफल!",
        "参数异常！": "पैरामीटर असामान्य है!",
        "获取中": "में प्राप्त करें...",
        "请完成任务单后进入": "कार्य सूची को पूरा करने के बाद",
        "请联系客服充值": "कृपया रिचार्ज करने के लिए ग्राहक सेवा से संपर्क करें",
        "请设置收款卡!": "कृपया एक कैश कार्ड सेट करें!",
        "功能已禁用!": "सुविधा अक्षम है!",
        "账号下线": "खाता ऑफ़लाइन",
        "登录/注册": "लॉगिन/पंजीकरण",
        "登录可享受更多服务！": "अधिक सेवाओं के लिए लॉग इन करें!",
        "未设置": "सेट नहीं किया गया",
        "已设置": "नियत",
        "提交": "जमा करना",
        "确定": "निश्चित करना",
        "请填写完整": "कृपया पूरा करें",
        "两次密码不一致！": "पासवर्ड दो बार असंगत हैं!",
        "设置提现密码成功！": "कैश आउट पासवर्ड सेट करने में सफल!",
        "提现密码已设置，需要修改请联系客服": "निकासी पासवर्ड सेट किया गया है, कृपया ग्राहक सेवा से संपर्क करें यदि आपको इसे संशोधित करने की आवश्यकता है",
        "已绑定": "बंधा हुआ",
        "无": "कोई नहीं",
        "更换头像成功！": "T ने अपने अवतार को सफलतापूर्वक बदल दिया!",
        "更新头像失败！": "अवतार अद्यतन करने में विफल!",
        "请勿重复设置！": "सेटिंग्स दोहराएं नहीं!",
        "设置姓名成功！": "नाम सेट करने में सफल!",
        "设置姓名失败！": "नाम सेट करने में विफल!",
        "设置性别成功！": "लिंग सफलता की स्थापना!",
        "设置性别失败！": "लिंग सेट करने में विफल!",
        "请设置姓名后再绑定银行卡！": "कृपया अपना नाम सेट करें और अपना बैंक कार्ड बांधें!",
        "请设置提现密码后再绑定银行卡！": "कृपया अपने बैंक कार्ड को बांधने से पहले एक निकासी पासवर्ड सेट करें!",
        "确认绑卡": "बाइंडिंग कार्ड की पुष्टि करें",
        "请输入银行卡号！": "कृपया बैंक दर्ज करें!",
        "请输入银行名称！": "कृपया एक बैंक नाम दर्ज करें!",
        "设置银行卡成功！": "बैंक कार्ड सफलतापूर्वक सेट करें!",
        "设置银行卡失败！": "बैंक कार्ड सेट करने में विफल!",
        "旧密码错误": "गलत पुराना पासवर्ड",
        "修改密码成功！": "पासवर्ड सफलतापूर्वक बदला गया!",
        "修改密码失败！": "पासवर्ड बदलने में विफल!",
        "请填写正确的金额": "कृपया सही राशि भरें",
        "提现金额不能少于：": "निकासी राशि से कम नहीं होनी चाहिए:",
        "提现金额不能大于：": "निकासी राशि से अधिक नहीं हो सकती है:",
        "当日提现次数已用完": "उस दिन निकासी का उपयोग किया गया है",
        "提现成功！": "निकासी सफल!",
        "提现失败！": "निकासी विफल!",
        "金额": "रकम",
        "待审核": "समीक्षा लंबित",
        "审核成功": "सफल लेखा परीक्षा",
        "审核退回": "लेखापरीक्षा वापसी",
        "请充值后观看视频！": "कृपया रिचार्ज करने के बाद वीडियो देखें!",
        "释放即可刷新": "रिफ्रेश करने के लिए रिलीज़ करें...",
        "加载中": "लोड हो रहा है...",
        "下拉即可刷新": "ताज़ा करने के लिए ड्रॉप-डाउन करें...",
    },
    setLang:{
        title: "भाषा सेट करें"
    },
    lang:{
        zh: "चीनी भाषा",
        jp: "जापान",
        ara: "भाषा",
        en: "अंग्रेज़ी",
        spa: "स्पेनी",
        it: "इटैलियन",
        de: "जर्मन भाषा",
        pt: "पुर्तगाली भाषा",
        fra: "फ्रेंच भाषा",
        nl: "डच भाषा",
        id: "इंडोनेशियाई भाषा",
        hi: "हिन्दी भाषा",
        kor: "कोरियाईName",
        tr: "तुर्की-भाषा",
        swe: "स्वीडिश भाषा",
        ga: "आयरिश-भाषा",
        ru: "रूसी-भाषा",
        vie: "विएतनामी",
        th: "थाई-भाषा",
        est: "एस्टोनियाई",
        be: "बेलारूसी",
        bul: "बल्गेरियाई",
        pl: "पोलिश भाषा",
        ms: "मलय",

    }
}
export default Hi