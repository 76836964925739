const Bul = {
    login: {
        title: "Вход",
        username: "Моля, въведете потребителско име",
        password: "Моля, въведете парола",
        forgetPaw: "Забравена парола",
        registerNow: "Няма акаунт? Регистрирайте се сега",
        doLogin: "Вход",

    },
    register:{
        title: "регистра́ция f",
        username: "Моля, въведете потребителско име (6-12 английски букви или цифри)",
        password: "Моля, въведете парола за вход (6-12 английски букви или цифри)",
        code: "Моля, въведете код за покана",
        agreement: "Познавам и съм съгласен с договорите на Споразумението за откриване на сметка",
        chooseAgreement: "Моля, проверете споразумението за откриване на акаунт по-долу!",
        doRegister: "регистра́ция f",

    },
    footer:{
        home : "Начало на страница",
        game: "угово́ря си угово́рба",
        video: "видео",
        mine: "мо́я",

    },
    game:{
        title: "Резервационна зала",
        all: "всички",

    },
    choose:{
        title: "наложница",
        city: "Енциклопедия на града",
        rule: "Тази платформа е най-автентичният + бизнес придружител + страст в един и същи град.За да се гарантира личната поверителност на всеки потребител, клиентите могат да се присъединят само като се свържат с рецепциониста или старши член на платформата, за да препоръчат истинско име.",
        price: "Ценови процес",
        resource: "Какви ресурси са налични?",
        character: "Уеб червено, модел, стюардеса, нежен модел, студент в колежа, само вие не можете да мислите за това, нищо не може да бъде",
        scope: "Обхват на услугата?",
        location: "Безплатни оръдия в един и същи град, въздушни кацания на всяко място в цялата страна, местни градове от първо и второ ниво в Китай, градове от трето ниво също могат да си уговорят срещи, като се свържат с рецепциониста.",

    },
    home:{
        recommend: "Препоръчителна задача",
        more: "Преглед на повече",
        popularity: "класация на популярност",
        hot: "Популярни препоръки",

    },
    lottery:{
        jieguo: "Резултат от гласуването",
        jiesu: "край",
        record: "Запис на мисията",
        rule: "Правила за игра",
        prompt: "Съвети за игра",
        gameplay: "Изберете номер от незадължителна сума и стойностна форма, за да заложите",
        explain: "Инструкции за печелене",
        wprole: "Общата стойност на трите лотарийни номера е 11-18; Общата стойност от 3 до 10 е малка;",
        example: "Пример за залагане",
        option: "План за залагане: Малък номер на теглене: 123, тоест малък и среден",
        task: "Списък със задачи",
        money: "Наличен баланс",
        submit: "изпра́тя",
        selection: "Текущ избор на номер",
        perNote: "Сума на залог",
        inputMoney: "Моля, въведете сумата",
        total: "общо",
        note: "общо",
        statistics: "общо",
        empty: "Изпразване на поръчката",
        verify: "Потвърждение за подаване",
        issue: "номера́ция f",
        num: "Номер на теглене",
        big: "голям",
        small: "малък",
        single: "едини́чен",
        double: "двойник",
        unselected: "Не е избран",
        drawLottery: "Успешно теглене, номер на периода:",
        kefu: "Свържете се с администратора за тази задача!",

    },
    mine:{
        recharge: "презарежда́м",
        withdraw: "теглене на пари",
        myMoney: "Портфейлът ми",
        detail: "подробности",
        balance: "балан́с m",
        finance: "Личен отчет",
        order: "Данни за акаунта",
        record: "Запис на мисията",
        my: "ли́чен център́",
        notice: "Информационно съобщение",
        service: "Онлайн обслужване на клиенти",
        score: "точки",
    },
    setting:{
        title: "Настройки на нещо",
        basic: "Основни настройки на информацията",
        password: "Парола за вход",
        capital: "Парола на фонда",
        lang: "ези́к m",
        quit: "Изход от системата",

    },
    setPayPassword: {
        title: "Задайте парола за финансиране",
        opw: "Моля, въведете парола за финансиране",
        tpw: "Моля, въведете паролата за финансиране отново",

    },
    gameRecord:{
        title: "Запис на мисията",
        amountBet: "Сума на залога",
        create: "Време за поръчка",
        update: "Време за сетълмент",

    },
    video:{
        title: "видео кино",
        play: "игра́я",

    },
    playVideo:{
        play: "& Показване",

    },
    setname:{
        title: "Промяна на истинското име",
        name: "Име",
        truename: "Моля, въведете истинско име",
        hint: "За сигурността на вашия акаунт истинското име трябва да съответства на името на обвързаната банкова карта",

    },
    setsex:{
        title: "Промяна на пола",

    },
    bindCard:{
        title: "Попълнете разписката",
        msg: "Моля, въведете информация за вашата разплащателна карта",
        hint: "Уважаеми потребители, за да защитите сигурността на вашите средства, моля, свържете истинското си име и задайте парола за теглене. Ако името не съответства на името на акаунта, няма да можете да изтеглите пари.",
        card: "Номер на сметка (нормален)",
        truecard: "Въведете акаунт (нормален)",
        name: "Име на акаунта (истинско име)",
        truename: "Въведете името на акаунта (истинско име)",
        financial_institution: "Име на финансовата институция",
        truefinancial_institution: "Въведете име на финансова институция",
        branch: "Име на клона",
        truebranch: "Въведете име на клон",
        branch_number: "Номер на клона",
        truebranch_number: "Въведете номера на реда"
    },
    setbank:{
        title: "Информация за разплащателната карта",
        bank: "Добавяне на банкова карта",
        hint: "Съвет: Моля, свържете се с голяма търговска банка, ако трябва да я модифицирате, моля, свържете се с онлайн обслужване на клиенти",

    },
    setLoginPassword:{
        title: "Промяна на паролата за вход",
        old_password: "Стара парола",
        old_password_p: "Моля, въведете стара парола",
        o_new_password: "Нова парола",
        o_new_password_p: "Моля, въведете нова парола",
        t_new_password_p: "Моля, въведете нова парола отново",

    },
    infomation:{
        title: "Основна информация",
        head: "авата́р m",
        select: "Избор на аватар",
        name: "Истинско име",
        sex: "пол",
        man: "мъ́ж m",
        girl: "жена",
        unknown: "неизвестен",
        binding: "Обвързване на информация",

    },
    withdraw: {
        title: "Център за теглене на пари",
        record: "Теглене на записи",
        money: "Теглена сума",
        all: "всички",
        min: "Единичен лимит на писалката: минимален",
        max: "",
        num: "Теглене: Теглене за един ден",
        hint: "Време за пристигане: Обикновено времето за пристигане е около 5 минути, най-бързото пристигане в рамките на 2 минути",
        quota: "Описание на лимита",
        balance: "балан́с m",
        withdraw: "Теглене сега",

    },
    withdrawRecord:{
        desc: "описа́ние n",
        create: "Време за изпращане",
        update: "Време за преглед",

    },
    personalreport:{
        title: "Личен отчет",
        profit: "Размер на печалбата",
        formula: "Формула за изчисляване на печалбата: печеливша сума-сума на задачата",
        task: "Сума на задачата",
        recharge: "су́ма за презаре́ждане",
        withdrawal: "Теглена сума",
        win_money: "Печеливша сума",

    },
    service: {
        title: "Онлайн обслужване на клиенти",
        relation: "контаќт m",
        hint: "7 * 24 часа на ден, посветен на вас",

    },
    profile: {
        portrait: "Фотография",
        video: "видео",
        jianjie: "ли́чен профил",
        liji: "Уговорете час сега",
        chongdan: "Диапазон на зареждане",
        fd: "да́ма f",
        yuyue: "угово́ря си угово́рба",
        archives: "Файл на момиче",
        age: "възра́ст m",
        height: "ръст",
        weight: "телесно тегло",
        cup: "ча́ша f",
        city: "град",
        area: "Област на обслужване",
        item: "Обслужване на артикули"
    },
    msg:{
        "次": "време",
        "用户不存在！": "Потребителят не съществува!",
        "密码错误！": "Грешна парола!",
        "登录成功！": "Влизането беше успешно!",
        "邀请码不存在！": "Кодът на поканата не съществува!",
        "用户名位数错误！": "Потребителското име е грешно!",
        "密码位数错误！": "Грешни цифри на паролата!",
        "用户名不能存在中文！": "Потребителското име не може да съществува на китайски!",
        "邀请码不能为空！": "Кодът на поканата не може да бъде празен!",
        "注册成功！": "Регистрацията беше успешна!",
        "注册失败！": "Регистрацията не бе успешна!",
        "用户已存在": "Потребителят вече съществува",
        "刷新成功！": "Обновяването беше успешно!",
        "请联系顾问或接待员": "Свържете се с консултант или рецепционист",
        "金额错误！": "Грешна сума!",
        "请选号！": "Моля, изберете номер!",
        "请填写金额!": "Моля, попълнете сумата!",
        "余额不足，请联系客服充值！": "Недостатъчен баланс, моля, свържете се с обслужването на клиентите за презареждане!",
        "没有更多了": "Не повече",
        "没有数据": "Няма данни",
        "余额不足！": "Недостатъчен баланс!",
        "扣款失败！": "Неуспешно приспадане!",
        "下注异常！": "Залогът е ненормален!",
        "投注成功！": "Залогът беше успешен!",
        "参数异常！": "Параметърът е ненормален!",
        "获取中": "Вземете...",
        "请完成任务单后进入": "Моля, попълнете списъка със задачи",
        "请联系客服充值": "Моля, свържете се с обслужване на клиенти за презареждане",
        "请设置收款卡!": "Моля, настройте разплащателна карта!",
        "功能已禁用!": "Функцията е деактивирана!",
        "账号下线": "Профил офлайн",
        "登录/注册": "Вход/регистрация",
        "登录可享受更多服务！": "Влезте за повече услуги!",
        "未设置": "Не е зададен",
        "已设置": "& Настройки:",
        "提交": "изпра́тя",
        "确定": "определям",
        "请填写完整": "Моля, попълнете",
        "两次密码不一致！": "Паролите са непоследователни два пъти!",
        "设置提现密码成功！": "Задаването на паролата за теглене беше успешно!",
        "提现密码已设置，需要修改请联系客服": "Паролата за теглене е зададена, моля, свържете се с обслужването на клиентите, ако трябва да",
        "已绑定": "Свързан е",
        "无": "не",
        "更换头像成功！": "T Смяната на аватарите беше успешна!",
        "更新头像失败！": "Актуализирането на аватара не бе успешно!",
        "请勿重复设置！": "Не повтаряйте настройката!",
        "设置姓名成功！": "Задаването на името беше успешно!",
        "设置姓名失败！": "Неуспешно задаване на име!",
        "设置性别成功！": "Настройката на пола е успешна!",
        "设置性别失败！": "Грешка при задаване на пол!",
        "请设置姓名后再绑定银行卡！": "Моля, задайте името си, преди да обвържете банковата си карта!",
        "请设置提现密码后再绑定银行卡！": "Моля, задайте парола за теглене, преди да обвържете банковата си карта!",
        "确认绑卡": "Потвърдете обвързването на картата",
        "请输入银行卡号！": "Моля, въведете банката!",
        "请输入银行名称！": "Моля, въведете името на банката!",
        "设置银行卡成功！": "Настройката на банковата карта беше успешна!",
        "设置银行卡失败！": "Настройката на банкова карта не бе успешна!",
        "旧密码错误": "Грешка при старата парола",
        "修改密码成功！": "Промяната на паролата беше успешна!",
        "修改密码失败！": "Грешка при промяна на паролата!",
        "请填写正确的金额": "Моля, попълнете правилната сума",
        "提现金额不能少于：": "Сумата за теглене не може да бъде по-малка от:",
        "提现金额不能大于：": "Сумата за теглене не може да бъде по-голяма от:",
        "当日提现次数已用完": "Изчерпано теглене в същия ден",
        "提现成功！": "Тегленето беше успешно!",
        "提现失败！": "Тегленето не бе успешно!",
        "金额": "су́ма f",
        "待审核": "В очакване на преглед",
        "审核成功": "Одитът беше успешен",
        "审核退回": "Одитирайте възвръщаемостта",
        "请充值后观看视频！": "Моля, гледайте видеото след презареждане!",
        "释放即可刷新": "Освобождаване за обновяване...",
        "下拉即可刷新": "Пуснете надолу, за да опресните...",
        "加载中": "Зареждане...",

    },
    setLang:{
        title: "Задаване на език"
    },
    lang:{
        zh: "китайски",
        jp: "Япония",
        ara: "ези́к m",
        en: "англи́йски ези́к",
        spa: "испански",
        it: "италиански",
        de: "немски",
        pt: "португалски",
        fra: "френски",
        nl: "холандски",
        id: "индонези́йски ези́к",
        hi: "хинди",
        kor: "коре́йски ези́к",
        tr: "турски",
        swe: "шведски",
        ga: "ирландски език",
        ru: "ру́ски m",
        vie: "виетнамски",
        th: "тай́йски ези́к",
        est: "есто́нски ези́к",
        be: "белару́ски ези́к",
        bul: "български",
        pl: "полски език",
        ms: "малайски език",

    }
}
export default Bul