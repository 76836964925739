const Kor = {
    login: {
        title: "로그인", //登录
        username:"사용자 이름을 입력하십시오",//请输入用户名
        password:"비밀번호를 입력하십시오.",//请输入密码
        forgetPaw:"비밀번호를 잊어버리다",//忘记密码
        registerNow:"계좌가 없어요?지금 등록하다",//没有账户？马上注册
        doLogin:"로그인",//登录

    },
    register:{
        title: "등기", //注册
        username:"사용자 이름 (영문 또는 숫자로 6~12자)을 입력하십시오",//请输入用户名(6-12个英文字母或数字)
        password:"로그인 비밀번호 (6~12자)를 입력하십시오",//请输入登录密码(6-12个英文字母或数字)
        code:"초대번호를 입력해주세요",//请输入邀请码
        agreement:"나는 이미\"구좌개설협의\"의 각 조약들을 알고 있으며 또한 이에 동의한다",//我已经知晓并同意"开户协议"各项条约
        chooseAgreement:"아래의 구좌개설 협의서를 체크하세요.",//请勾选下方开户协议！
        doRegister:"등기",//注册
    },
    footer:{
        home :"홈페이지",//首页
        game:"예약(하다)．",//预约
        video:"동영상",//视频
        mine:"나의",//我的
    },
    game:{
        title:"예약 홀",//预约大厅
        all:"전체",//全部
    },
    choose:{
        title:"위해",//选妃
        city:"도시 대전 」",//城市大全
        rule:"본 플랫폼 전체 넷의 가장 진실한 외곽 + 비즈니스 투어 + 도시 열정. 매 사용자의 사적인 프라이버시를 보장하기 위해 고객은 접대원 또는 플랫폼 베테랑 회원의 실명으로 추천해야만 가입할수 있다.",//本平台全网最真实外围+商务伴游+同城激情，为保证每个用户的个人隐私，客户仅限通过联系接待员或平台资深会员实名推荐才可加入。
        price:"가격흐름",//价格流程
        resource:"부분적인 것을 나타내다．",//有哪些资源?
        character:"왕훙, 모델, 스튜어디스, 젊은 모델, 대학생 등 당신만 생각지 못했을 뿐 본 플랫폼이 없으면 불가능합니다",//网红、模特、空姐、嫩模、大学生，只有您想不到，没有本平台办不到
        scope:"서비스 범위?",//服务范围?
        location:"국내 제1, 2 선 도시에는 당지에 모두 있고 제3 선 도시에서도 접대원과 련계를 통하여 예약하고 배치할수 있다.",//同城免费约炮，任意地点全国空降，国内一二线城市当地均有，三线城市也可通过联系接待员预约安排。
    },
    home:{
        recommend:"임무 추천",//推荐任务
        more:"더 보기",//查看更多
        popularity:"인기 순위",//人气排行
        hot:"인기 추천",//热门推荐
    },
    lottery:{
        jieguo: "투표 결과",
        jiesu: "끝나다",
        record:"임무 기록",//任务记录
        rule:"게임 규칙",//玩法规则
        prompt:"게임 힌트",//玩法提示
        gameplay:"선택 가능한 값 형태에서 번호를 선택해 베팅하십시오",//从可选和值形态里面选择号码进行下注
        explain:"당첨 안내서",//中奖说明
        wprole:"3개의 추첨번호의 합계가 11~18 이면 큰것이고합계가 3~10 이면 작으며",//三个开奖号码总和值11~18 为大;总和值3~ 10为小;
        example:"투자 범례",//投注范例
        option:"베팅 계획:작은 추첨 번호:123 즉 중소",//投注方案：小 开奖号码：123,即中小
        task:"작업 명세서",//任务单
        money:"사용가능잔고",//可用余额
        submit:"내다",//提交
        selection:"현재 선택 번호",//当前选号
        perNote:"매건 금액",//每注金额
        inputMoney:"금액을 입력하십시오",//请输入金额
        total:"전부",//总共
        note:"전부",//总共
        statistics:"합계하다",//合计
        empty:"주문서 정리",//清空订单
        verify:"제출 확인",//确认提交
        issue:"발신 일자와 문서 번호",//期号
        num:"추첨 번호",//开奖号码
        big:"크다.",//大
        small:"작다",//小
        single:"하나의.",//单
        double:"켤레",//双
        unselected:"미래의 선택",//未选择
        drawLottery:"당첨첨 성공, 기간:",//开奖成功，期号：
        kefu:"이 작업을 보려면 관리자에게 연락하십시오!",//请联系管理员领取该任务!
    },
    mine:{
        recharge:"충전시키다",//充值
        withdraw:"현금을 인출하다.",//提现
        myMoney:"내 지갑",//我的钱包
        detail:"상세한 상황.",//详情
        balance:"잔액",//余额
        finance:"개인 보고서",//个人报表
        order:"계정세부정보",//账户明细
        record:"임무 기록",//任务记录
        my:"개인 센터",//个人中心
        notice:"정보 공고",//信息公告
        service:"온라인 고객서비스",//在线客服
        score: "적분",
    },
    setting:{
        title:"설치",//设置
        basic:"기본 정보 설정",//基本信息设置
        password:"로그인 비밀번호",//登录密码
        capital:"자금 비밀번호",//资金密码
        lang:"언어",//语言
        quit:"로그아웃",//退出登录
    },
    setPayPassword: {
        title:"자금 비밀번호를 설정하다.",//设置资金密码
        opw:"자금 비밀번호를 입력하세요",//请输入资金密码
        tpw:"자금 비밀번호를 다시 입력하세요",//请再次输入资金密码
    },
    gameRecord:{
        title:"임무 기록",//任务记录
        amountBet:"베팅 금액",//下注金额
        create:"주문 발주 시간",//下单时间
        update:"결산 시간",//结算时间
    },
    video:{
        title:"비디오 극장",//视频影院
        play:"방송",//播放
    },
    playVideo:{
        play: "다음 재생",//次播放
    },
    setname:{
        title:"실명을 고치다",//修改真实姓名
        name:"성명",//姓名
        truename:"실명을 입력해 주십시오",//请输入真实姓名
        hint:"계정 보안을 위해 바인딩 카드의 이름과 실명이 일치해야 합니다",//为了您账户安全,真实姓名需要与绑定银行卡姓名一致
    },
    setsex:{
        title:"성별 수정",//性别修改
    },
    bindCard:{
        title:"수금 카드를 쓰다",//填写收款卡
        msg:"수금카드 정보를 입력하십시오",//请输入您的收款卡信息
        hint:"고객님, 고객님의 자금안전을 위해 고객님의 실명과 인출 비밀번호를 설정해 주십시오. 이름과 계좌이름이 일치하지 않으면 인출할 수 없습니다",//尊敬的用户,为了保障您的资金安全,请您绑定您的真实姓名和设置取款密码,如果姓名与开户名不一致,将无法取款
        card: "계정 번호(일반)",
        truecard: "계정 번호 입력(일반)",
        name: "계정 이름(진짜 이름)",
        truename: "계정 이름(진짜 이름)을 입력합니다.",
        financial_institution: "금융기관명",
        truefinancial_institution: "금융 기관 이름 입력",
        branch: "지점 이름",
        truebranch: "은행 지점명 입력",
        branch_number: "은행 지점 번호",
        truebranch_number: "은행 지점 번호 입력"
    },
    setbank:{
        title:"수금카드 정보",//收款卡信息
        bank:"카드 추가",//添加银行卡
        hint:"힌트:대형 상업 은행에 바인딩, 수정이 필요한 경우 온라인 고객 서비스에 연락하십시오",//提示:请绑定大型商业银行,如需修改,请您联系在线客服
    },
    setLoginPassword:{
        title:"로그인 비밀번호를 수정하다",//修改登录密码
        old_password:"이전 비밀번호",//旧密码
        old_password_p:"이전 비밀번호를 입력하십시오",//请输入旧密码
        o_new_password:"새 비밀번호",//新密码
        o_new_password_p:"새 비밀번호를 입력하십시오",//请输入新密码
        t_new_password_p:"새 비밀번호를 다시 입력하십시오",//请再次输入新密码
    },
    infomation:{
        title:"기본정보",//基本信息
        head:"두상",//头像
        select:"얼굴 선택",//选择头像
        name:"실명",//真实姓名
        sex:"성별",//性别
        man:"남",//男
        girl:"여자",//女
        unknown:"아직 모르다.",//未知
        binding:"정보 바인딩",//绑定信息
    },
    withdraw: {
        title:"현금인출센터",//提现中心
        record:"현금 인출 기록",//提现记录
        money:"현금 인출 금액",//提现金额
        all:"모두",//全部
        min:"1인당 한도액:최저",//单笔限额：最低
        max:"최고",//最高
        num:"현금인출횟수:하루최고인출횟수",//提现次数：一天最高提现
        hint:"도착 시간:보통 도착 시간은 5분 정도, 가장 빠른 2분 이내에 도착합니다",//到账时间：一般到账时间在5分钟左右，最快2分钟内到账
        quota:"한도액 설명",//限额说明
        balance:"잔액",//余额
        withdraw:"즉시 현금을 인출하다.",//马上提现
    },
    withdrawRecord:{
        desc:"해설",//说明
        create:"제출 날짜",//提交时间
        update:"심사 시간",//审核时间
    },
    personalreport:{
        title:"개인 보고서",//个人报表
        profit:"이윤 금액",//盈利金额
        formula:"이윤 계산공식:당첨금-퀘스트 금액",//盈利计算公式 : 中奖金额 - 任务金额
        task:"작업 금액",//任务金额
        recharge:"충전 금액",//充值金额
        withdrawal:"현금 인출 금액",//提现金额
        win_money:"당첨금",//中奖金额
    },
    service: {
        title:"온라인 고객서비스",//在线客服
        relation:"연락하다",//联系
        hint:"하루 7 * 24시간 성심성의껏 당신을 위해 서비스합니다",//全天7 * 24小时竭诚为您服务
    },
    msg:{
        "次":"차",
        "用户不存在！":"사용자가 존재하지 않습니다!",//用户不存在！
        "密码错误！":"암호가 잘못되었습니다!",
        "登录成功！":"로그인 성공했습니다!",
        "邀请码不存在！":"초대장이 존재하지 않습니다!",
        "用户名位数错误！":"잘못된 사용자 이름 자리!",
        "密码位数错误！":"비밀번호가 잘못되었습니다!",
        "用户名不能存在中文！":"사용자 이름은 중국어로 존재할 수 없습니다!",
        "邀请码不能为空！":"초대장 코드가 비어 있으면 안 됩니다!",
        "注册成功！":"등록에 성공했습니다!",
        "注册失败！":"등록에 실패했습니다!",
        "用户已存在":"사용자가 이미 존재함",
        "刷新成功！":"새로 고침 성공했습니다!",
        "请联系顾问或接待员":"상담원이나 접대원에게 연락해 주세요",
        "金额错误！":"금액 오류!",
        "请选号！":"번호를 선택해 주세요.",
        "请填写金额!":"금액을 적어주세요!",
        "余额不足，请联系客服充值！":"잔액이 부족하니, 고객센터에 연락하여 충전하시기 바랍니다!",
        "没有更多了":"더 이상은 없습니다",
        "没有数据":"자료가 없습니다.",
        "余额不足！":"잔고가 부족하다!",
        "扣款失败！":"비용 공제 실패!",
        "下注异常！":"베팅이 이상하다!",
        "投注成功！":"베팅에 성공했다!",
        "参数异常！":"인자 이상!",
        "获取中":"가져오는 중...",
        "请完成任务单后进入":"작업 목록을 작성한 후에 들어가세요",
        "请联系客服充值":"고객센터로 연락해서 충전해주세요",
        "请设置收款卡!":"수금카드를 설정하세요!",
        "功能已禁用!":"기능이 비활성화됨!",
        "账号下线":"계좌 번호를 해제하다",
        "登录/注册":"로그인",
        "登录可享受更多服务！":"로그인하시면 더 많은 서비스를 이용하실 수 있습니다!",
        "未设置":"설정되지 않음",
        "已设置":"설정됨",
        "提交":"내다",
        "确定":"잡다",
        "请填写完整":"완전하게 작성하십시오",
        "两次密码不一致！":"비밀번호가 일치하지 않습니다!",
        "设置提现密码成功！":"현금 인출 비밀번호를 성공적으로 설정했습니다!",
        "提现密码已设置，需要修改请联系客服":"현금 인출 비밀번호가 이미 설정되었으니, 수정이 필요하시면 고객 서비스 팀에 연락 주세요",
        "已绑定":"바인딩됨",
        "无":"없다",
        "更换头像成功！":"성공적으로 아바타 변경!",
        "更新头像失败！":"아바타 업데이트 실패!",
        "请勿重复设置！":"중복 설정하지 마십시오!",
        "设置姓名成功！":"이름 설정이 성공했습니다!",
        "设置姓名失败！":"이름을 설정할 수 없습니다!",
        "设置性别成功！":"성별 설정이 성공했습니다!",
        "设置性别失败！":"성별 설정에 실패했습니다!",
        "请设置姓名后再绑定银行卡！":"이름을 설정하신 후 은행 카드를 바인딩하세요!",
        "请设置提现密码后再绑定银行卡！":"현금 인출 비밀번호를 설정하신 후 은행 카드를 묶으세요!",
        "确认绑卡":"묶음 확인 카드",
        "请输入银行卡号！":"은행 카드번호를 입력하세요!",
        "请输入银行名称！":"은행명을 입력하세요!",
        "设置银行卡成功！":"카드 설정 성공!",
        "设置银行卡失败！":"카드 설정 실패!",
        "旧密码错误":"이전 비밀번호 오류",
        "修改密码成功！":"비밀번호 변경에 성공했습니다!",
        "修改密码失败！":"비밀번호 변경에 실패했습니다!",
        "请填写正确的金额":"정확한 금액을 기입해 주세요",
        "提现金额不能少于：":"현금인출금액은 다음보다 적어서는 안된다.",
        "提现金额不能大于：":"인출금액은 다음보다 커서는 안된다.",
        "当日提现次数已用完":"당일의 현금 인출 횟수가 이미다 떨어졌다",
        "提现成功！":"현금 인출 성공!",
        "提现失败！":"현금 인출 실패!",
        "金额":"금액",
        "待审核":"심의를 기다리다",
        "审核成功":"감사 성공",
        "审核退回":"심사하여 돌려주다",
        "请充值后观看视频！":"충전하시고 동영상 시청하세요!",
        "释放即可刷新":"릴리스하면 새로 고침...",
        "加载中":"불러오는 중...",
        "下拉即可刷新": "드롭다운하여 새로 고치기...",
    },
    setLang:{
        title:"언어 설정"
    },
    profile: {
        portrait: "사진",
        video: "비디오",
        jianjie: "개인 프로필",
        liji: "지금 예약",
        chongdan: "충전 범위",
        fd: "귀부",
        yuyue: "예약",
        archives: "여동생 파일",
        age: "나이",
        height: "키",
        weight: "몸무게",
        cup: "컵 뚜껑",
        city: "시",
        area: "서비스 지역",
        item: "서비스 품목"
    },
    lang:{
        "zh": "중국어",
        "jp": "일본",
        "ara": "말",
        "en": "영어",
        "spa": "스페인어",
        "it": "이탈리아어",
        "de": "독일어",
        "pt": "포르투갈어",
        "fra": "프랑스어",
        "nl": "네덜란드어",
        "id": "인도네시아어",
        "hi": "힌디어",
        "kor": "한국어",
        "tr": "터키어",
        "swe": "스웨덴어",
        "ga": "아일랜드어",
        "ru": "러시아어",
        "vie": "베트남어",
        th: "태국어",
        est: "에스토니아어",
        be: "벨라루스어",
        bul: "불가리아어",
        pl: "폴란드어",
        ms: "말레이어",
    }
}
export default  Kor